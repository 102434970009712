.heade-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333333;
}

.header-li {
  float: left;
}

.header-li-a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.header-li-a:hover {
  background-color: #111111;
  color: white;
}

#nav-links a.active {
  color: red !important;
}

.range-color {
  width: 100%;
  accent-color: #F05336;
}

/* Styles for the custom slider track Vertical */
.vertical-slider {
  display: flex;
  align-items: center;
  height: 250px;
  margin-left: 10px;
}

/* Styles for the custom slider track Horizontal */
.custom-track {
  height: 4px;
  border-radius: 2px;
  top: 8px;

}

/* Styles for the custom slider track Vertical */
.custom-track-vertical {
  border-radius: 2px;
  left: 8px;
  width: 5px;
}

.lower-track {
  background-color: #e9ecef;
}

.mid-track {
  background-color: #F05336;
}

.upper-track {
  background-color: #e9ecef
}

/* Styles for the custom slider thumb (the draggable handle) */
.custom-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  background-color: #F05336;
  cursor: grab;
}

.custom-thumb:focus {
  outline: none;
}

.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 500px;
  overflow-y: auto;
}


.image-preview-box {
  width: 150px;
  height: 150px;
  border: 2px solid #7e7e8a;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.image-preview-image {
  width: 150px;
  height: 150px;
  border: 2px solid #7e7e8a;
}

.booking-upload-box {
  width: 600px;
  height: 280px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: white;
}

.custom-icon-file-uploader {
  border: none;
}

.display-none {
  display: none;
}

.icon-thumbnail {
  margin-right: 20px;
  width: 40px;
}

.outline-box {
  width: 20% !important;
  text-align: center;
  cursor: pointer
}

@media screen and (max-width: 992px) {
  .outline-box {
    width: 25% !important;
    text-align: center;
    cursor: pointer;

  }
}

@media screen and (max-width: 825px) {
  .outline-box {
    width: 29% !important;
    text-align: center;
    cursor: pointer;
  }
}

@media screen and (max-width: 775px) {
  .outline-box {
    width: 49% !important;
    text-align: center;
    cursor: pointer;
  }
}

.mobile-image-width {
  width: 15rem !important;
}

@media screen and (max-width: 576px) {
  .mobile-image-width {
    width: 95% !important;

  }
}

.compare-border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  width: 95% !important;
}

/* Add a class name to the parent container for the car details grid */
.car-details-grid {
  display: flex;
  justify-content: space-between;
}

/* Style the left and right columns to add padding */
.car-details-grid .col {
  padding: 0 10px; /* Adjust the padding as needed */
}

/* Style the horizontal lines within the rows */
.car-details-grid .row hr {
  margin: 5px 0; /* Adjust the margin as needed */
  border: none;
  border-top: 1px solid #000; /* Change the color as needed */
}
